import React, { useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import CategoryItem from "./CategoryItem";
import apiClient from "./../api/client";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 20px;
  justify-content: space-between;

  ${mobile({
    padding: "0px",
    flexDirection: "row",
    // margin: "20px",
    width: "100%",
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    scrollBarWidth: "none",
    paddingTop: "20px",
  })}

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Categories = () => {
  const [data, setData] = useState();
  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
  const scrl = useRef(0);
  useEffect(() => {
    fetchCategories();
  }, []);

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");

    let sortedData = data?.sort((p1, p2) =>
      p1._id > p2._id ? 1 : p1._id < p2._id ? -1 : 0
    );
    const x = sortedData[5];
    for (let i = 5; i >= 4; i--) {
      sortedData[i] = sortedData[i - 1];
    }
    sortedData[3] = x;

    setData(data);
  };
  // console.log(data);
  return (
    <div>
      <div className="slider">
        {scrollX !== 0 && (
          <button className="prev" onClick={() => slide(-180)}>
            <i className="fa fa-angle-left"></i>
          </button>
        )}
        <Container ref={scrl} onScroll={scrollCheck}>
          {data?.map((item) => (
            <CategoryItem item={item} key={item.id} />
          ))}
        </Container>
        {!scrolEnd && (
          <button className="next" onClick={() => slide(+180)}>
            <i className="fa fa-angle-right"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default Categories;
