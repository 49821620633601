import React from "react";
import Footer from "../../components/Footer";

const TermsConditions = () => {
  return (
    <div
      style={{
        marginTop: "170px",
        textAlign: "justify",
        padding: "20px",
      }}
    >
      <h2 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Terms of use
      </h2>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        “Subhash Super Store” is a unit of “Mittal Trading Company" at C-211 , C
        – Block Indira Nagar, Lucknow. The domain name www.SubhashSuperStore.in
        is owned by the Company. The Company holds a FSSAI license no.
        12723066000661 " Subhash Super Store " is owned & managed by " Mittal
        Trading Company”. The Company GSTIN number is 09AEWPA0282Q1ZU.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Acceptance of terms
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        Thank you for using Subhash Super Store. These Terms of Use (the
        "Terms") are intended to make you aware of your legal rights and
        responsibilities with respect to your access to and use of the
        SubhashSuperStore.in website at www.SubhashSuperStore.in (the "Site")
        and any related mobile or software applications ("SubhashSuperStore
        Platform") including but not limited to delivery of information via the
        website whether existing now or in the future that link to the Terms
        (collectively, the "Services"). These Terms are effective for all
        existing and future SubhashSuperStore customers. By accessing this site
        (hereinafter the “Marketplace”), you agree to be bound by the same and
        acknowledge that it constitutes an agreement between you and the Company
        (hereinafter the “User Agreement”). You may not use the Services if you
        do not accept the Terms or are unable to be bound by the Terms. Your use
        of the SubhashSuperStore Platform is at your own risk, including the
        risk that you might be exposed to content that is objectionable, or
        otherwise inappropriate. This document is published in accordance with
        the provisions of Rule 3 of the Information Technology (Intermediaries
        Guidelines) Rules, 2011. The User Agreement may be updated from time to
        time by the Company without notice. It is therefore strongly recommended
        that you review the User Agreement, as available on the Marketplace,
        each time you access and/or use the Marketplace. The terms ‘visitor(s)’,
        ‘user(s)’, ‘you’ hereunder refer to the person visiting, accessing,
        browsing through and/or using the Marketplace at any point in time.
        Should you have need clarifications regarding the Terms of Use, please
        do write to us at at info@ SubhashSuperStore.in
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Services overview
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        The Marketplace is a platform for domestic consumers to transact with
        third party sellers, who have been granted access to the Marketplace to
        display and offer products for sale through the Marketplace. For
        abundant clarity, the Company does not provide any services to users
        other than providing the Marketplace as a platform to transact at their
        own cost and risk, and other services as may be specifically notified in
        writing. The Company is not and cannot be a party to any transaction
        between you and the third party sellers, or have any control,
        involvement or influence over the products purchased by you from such
        third party sellers or the prices of such products charged by such
        third-party sellers. The Company therefore disclaims all warranties and
        liabilities associated with any products offered on the Marketplace.
        Services on the Marketplace are available to only select geographies in
        Lucknow, and are subject to restrictions based on business hours.
        Transactions through the Marketplace may be subject to a delivery charge
        where the minimum order value is not met. You will be informed of such
        delivery charges at the stage of check-out for a transaction through the
        Marketplace. Transactions through the Marketplace may be subject to a
        convenience & safety fee. You will be informed of such convenience &
        safety fee at the stage of check-out for a transaction through the
        Marketplace.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Eligibility
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        Persons who are “incompetent to contract” within the meaning of the
        Indian Contract Act, 1872 including minors, undischarged insolvents etc.
        are not eligible to use/access the Marketplace. However, if you are a
        minor, i.e. under the age of 18 years, you may use/access the
        Marketplace under the supervision of an adult parent or legal guardian
        who agrees to be bound by these Terms of Use. You are however prohibited
        (even under provision) from purchasing any product(s) which is for adult
        consumption, the sale of which to minors is prohibited. The Marketplace
        is intended to be a platform for end-consumers desirous of purchasing
        product(s) for domestic self-consumption. If you are a retailer,
        institution, wholesaler or any other business user, you are not eligible
        to use the Marketplace to purchase products. The Company, in its sole
        discretion and without liability, reserves the right to terminate or
        refuse your registration, or refuse to permit use/access to the
        Marketplace, if: (i) it is discovered or brought to notice that you do
        not conform to the eligibility criteria, or (ii) the Company has reason
        to believe (including through evaluating usage patterns) that the
        eligibility criteria is not met/is violated by a user, or (iii) may
        breach the terms of this User Agreement. In order to determine
        compliance with eligibility criteria, the Company inter alia uses an
        algorithm and/or pre-determined criteria based technology and
        accordingly, from time to time, your usage may be restricted or blocked
        on account of overlap with such algorithms/predetermined criteria. In
        such cases, if you are a genuine domestic user of the Platform, please
        contact us for assistance.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Licence & access
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        The Company grants you a limited sub-license to access and make personal
        use of the Marketplace, but not to download (other than page caching) or
        modify it, or any portion of it, except with express prior written
        consent of the Company. Such limited sublicense does not include/permit
        any resale or commercial use of the Marketplace or its contents; any
        collection and use of any product listings, descriptions, or prices. The
        Marketplace or any portion of the Marketplace may not be reproduced,
        duplicated, copied, sold, resold, visited, or otherwise exploited for
        any commercial purpose without express prior written consent of the
        Company You may not frame or utilize framing techniques to enclose any
        trademark, logo, or other proprietary information (including images,
        text, page layout, or form) of the Marketplace or of the Company and/or
        its affiliates without the express prior written consent of the Company.
        You may not use any meta tags or any other “hidden text” utilizing the
        Company’s name or trademarks without the express prior written consent
        of the Company. You shall not attempt to gain unauthorized access to any
        portion or feature of the Marketplace, or any other systems or networks
        connected to the Marketplace or to any server, computer, network, or to
        any of the services offered on or through the Marketplace, by hacking,
        ‘password mining’ or any other illegitimate means. You hereby agree and
        undertake not to host, display, upload, modify, publish, transmit,
        update or share any information which: · Belongs to another person and
        to which you do not have any right; · Is grossly harmful, harassing,
        blasphemous, defamatory, obscene, pornographic, paedophilic, libelous,
        invasive of another’s privacy, hateful, or racially, ethnically
        objectionable, disparaging, relating or encouraging money laundering or
        gambling, or otherwise unlawful in any manner whatever; · Harms minors
        in any way; · Infringes any patent, trademark, copyright or another
        proprietary/intellectual property right; · Violates any law for the time
        being in force; · Deceives or misleads the addressee about the origin of
        such messages communicates any information which is grossly offensive or
        menacing in nature; · Contains software viruses or any other computer
        code, files or programs designed to interrupt, destroy or limit the
        functionality of any computer resource; · Threatens the unity,
        integrity, defence, security or sovereignty of India, friendly relations
        with foreign states, or public order or causes incitement to the
        commission of any cognizable offense or prevents investigation of any
        offense or is insulting any other nation; · Is misleading or known to be
        false in any way. Any unauthorized use shall automatically terminate the
        permission or sub-license granted by the Company.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Account & registration obligations
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        All users must register and log in for placing orders on the
        Marketplace. You must keep your account and registration details current
        and correct for all communications related to your purchases from the
        Marketplace. By agreeing to the Terms of Use, you agree to receive
        promotional or transactional communication and newsletters from the
        Company. You can opt out from such communication and/or newsletters
        either by contacting the customer services team of Marketplace or by
        placing a request for the same. As part of the registration process on
        the Marketplace, the Company may collect the following personally
        identifiable information about you, including but not limited to name,
        email address, age, address, mobile phone number, and other contact
        details, demographic profile (like your age, gender, occupation,
        education, address etc.) and information about the pages on the
        Marketplace you visit/access, the links you click on the Marketplace,
        the number of times you access a particular page/feature and any such
        information. Information collected about you is subject to the privacy
        policy of the Company (https:// SubhashSuperStore /privacy), which is
        incorporated in these Terms of Use by reference. By registering your
        mobile number with us, you provide your consent to be contacted by us
        via phone calls, SMS notifications, mobile applications and/or any other
        electronic mode of communication in case of any order or shipment or
        delivery related updates.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>Pricing</h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        The Company aims to ensure that prices of all products offered for sale
        are true and correct. However, from time to time, the prices of certain
        products may not be current or may be inaccurate on account of technical
        issues, typographical errors or incorrect product information provided
        to the Company. In each such case, notwithstanding anything to the
        contrary, the Company reserves the right to cancel the order without any
        further liability. Subject to the foregoing, the price mentioned at the
        time of ordering a product shall be the price charged at the time of
        delivery, provided that no product offered for sale on the Marketplace
        will be sold at a price higher than its MRP (maximum retail price).
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Disclaimers
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        · You acknowledge and undertake that you are accessing the services on
        the website and transacting at your own risk and are using your best and
        prudent judgement before entering into any transactions. The Company
        makes best efforts to display available Products, including in respect
        of their colour, size, shape and appearance, as accurately as possible.
        However, as the same are representative accordingly the actual color,
        size, shape and appearance may have variations from the depiction on
        your mobile/computer screen. · The Company does not make any
        representation or warranties in respect of the products available on the
        Marketplace nor does the Company implicitly or explicitly support or
        endorse the sale or purchase of any products on the Marketplace. The
        Company accepts no liability for any error. · You acknowledge and agree
        that the Company shall not, at any time, have any right, title or
        interest to any product(s), which is subject to a bipartite sale and
        purchase contract between you. The Company does not have any control
        over the quality, failure to provide or any other aspect whatsoever of
        the product(s) and is not responsible for damages or delays on account
        of products which are out of stock, unavailable or back ordered.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Reviews, feedback, submissions
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        • All reviews, comments, feedback, suggestions, ideas, and other
        submissions disclosed, submitted or offered on the Marketplace or
        otherwise disclosed, submitted or offered in connection with use of the
        Marketplace (collectively, the Comments) shall be and remain the
        property of the Company. Such disclosure, submission or offer of any
        Comments shall constitute an assignment to the Company of all worldwide
        rights, titles and interests in all copyrights and other intellectual
        properties in the Comments. Thus, the Company shall exclusively own all
        such rights, titles and interests in the Comments and shall not be
        limited in any way in its use, commercial or otherwise. • The Company
        will be entitled to use, reproduce, disclose, modify, adapt, create
        derivative works from any Comments, and publish, display and distribute
        any Comments submitted for any purpose whatsoever without restriction
        and without compensating the user in any way. The Company is and shall
        be under no obligation to: (i) maintain any Comments in confidence; or
        (ii) pay compensation for any Comments; or (iii) respond to any
        Comments. You agree that any Comments submitted by you on the
        Marketplace will not violate the Terms of Use or any right of any third
        party, including copyright, trademark, privacy or other personal or
        proprietary right(s), and will not cause injury to any person or entity.
        You further agree that no Comments submitted by you on the Marketplace
        will be or contain libellous or otherwise unlawful, threatening, abusive
        or obscene material, or contain software viruses, political campaigning,
        commercial solicitation, chain letters, mass mails or any form of
        ‘spam’. The Company does reserve the right (but assumes no obligation)
        to monitor, edit and/or remove any Comments submitted on the
        Marketplace. You hereby grant the Company the right to use names that
        you submit in connection with any Comments. You agree not to use a false
        email address, impersonate any person or entity, or otherwise mislead as
        to the origin of any Comments you submit. You are, and shall remain,
        responsible for the content of any Comments you make and you agree to
        indemnify the Company and its affiliates against all claims, loss and
        liabilities resulting from any Comments you submit. • Further, any
        reliance placed on Comments available on the Marketplace from a third
        party shall be at your sole risk and expense.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Objectionable material
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        You understand that by using the Marketplace or any services provided on
        the Marketplace, you may encounter content that may be deemed by some to
        be offensive, indecent, or objectionable, which content may or may not
        be identified as such. You agree to use the Marketplace and any service
        at your sole risk and that to the fullest extent permitted under
        applicable law, the Company and its affiliates shall have no liability
        to you for any content that may be deemed offensive, indecent, or
        objectionable to you.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>Indemnity</h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        You agree to defend, indemnify and hold harmless the Company, its
        employees, directors, officers, agents and their successors and assigns
        from and against any and all claims, liabilities, damages, losses, costs
        and expenses, including attorney’s fees, caused by or arising out of
        claims based upon a breach of any warranty, representation or
        undertaking in this User Agreement, or arising out of a violation of any
        applicable law (including but not limited in relation to intellectual
        property rights, payment of statutory dues and taxes, claims of libel,
        defamation, violation of rights of privacy or publicity, loss of service
        by other subscribers and infringement of intellectual property or other
        rights). This clause shall survive the expiry or termination of this
        User Agreement.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Limitation of liability
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        • The aggregate liability of the Company, if any, that is established
        and ordered by a court of competent jurisdiction pursuant to a claim,
        shall in no event extend beyond refund of the money charged from a user
        for purchases made pursuant to an order under which such liability has
        arisen and been established. • It is acknowledged and agreed that
        notwithstanding anything to the contrary, the Company shall not be
        liable, under any circumstances, whether in contract or in tort, for any
        indirect, special, consequential or incidental losses or damages,
        including on grounds of loss of profit, loss of reputation or loss of
        business opportunities.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Termination
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        • This User Agreement is effective unless and until terminated, either
        by you or by the Company. You may terminate this User Agreement at any
        time, provided that you discontinue any further use of the Marketplace.
        The Company may terminate this User Agreement at any time and may do so
        immediately without notice, and accordingly deny you access to the
        Marketplace. • The Company’s right to any comments and to be indemnified
        pursuant to the terms hereof, shall survive any termination of this User
        Agreement. Any such termination of the User Agreement shall not cancel
        your obligation to pay for product(s) already ordered from the
        Marketplace or affect any liability that may have arisen under the User
        Agreement prior to the date of termination.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Governing & jurisdiction
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        • The User Agreement shall be governed by and construed in accordance
        with the laws of India, without giving effect to the principles of
        conflict of laws thereunder. • Any dispute or difference, whether on the
        interpretation or otherwise, in respect of any terms hereof shall be
        referred to an independent arbitrator to be appointed by the Company.
        Such an arbitrator’s decision shall be final and binding on the parties.
        The arbitration shall be in accordance with the Arbitration and
        Conciliation Act, 1996, as amended or replaced from time to time. The
        seat of arbitration shall be Delhi and the language of the arbitration
        shall be English. • Subject to the aforesaid, the Courts at Lucknow
        shall have exclusive jurisdiction over any proceedings arising in
        respect of the User Agreement. Subhash Super Store Commerce Private
        Limited C-211 Indira Nagar Lucknow. Email
        address:info@SubhashSuperStore.in Time: Monday - Friday (09:00 hours to
        18:00 hours) Please note: SubhashSuperStore does not solicit
        confidential information such as OTP/CVV/PIN NUMBER/Card number either
        through call or mail or any other means. Please do not reveal these
        details to fraudsters and imposters claiming to be calling on
        SubhashSuperStore’s behalf. You may report such suspicious activities to
        info@SubhashSuperStore.in
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Communication with the Company
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        If you wish to correct or update any information you have provided, you
        may do so online, on the website itself. Alternatively, you may contact
        the Company to correct or update such information by sending an email
        to: info@SubhashSuperStore.in In the event of loss of access to the
        website, you may contact the Company by sending an email to:
        info@SubhashSuperStore.in
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Privacy policy
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        “SubhashSuperStore” is a trademark of " SubhashSuperStore” (formerly
        known as Mittal Trading Company) (“Company”), a company incorporated
        under the Companies Act, 2013 with its registered and corporate office
        at Indira Nagar Lucknow The domain name SubhashSuperStore.in is owned by
        the Company The Company respects your privacy and values the trust you
        place in it. Set out below is the Company’s ‘Privacy Policy’ which
        details the manner in which information relating to you is collected,
        used and disclosed. Customers are advised to read and understand our
        Privacy Policy carefully, as by accessing the website/app you agree to
        be bound by the terms and conditions of the Privacy Policy and consent
        to the collection, storage and use of information relating to you as
        provided herein. If you do not agree with the terms and conditions of
        our Privacy Policy, including in relation to the manner of collection or
        use of your information, please do not use or access the website/app.
        Our Privacy Policy is incorporated into the Terms and Conditions of Use
        of the website/app and is subject to change from time to time without
        notice. It is strongly recommended that you periodically review our
        Privacy Policy as posted on the App/Web. Should you have any
        clarifications regarding this Privacy Policy, please do not hesitate to
        contact us at info@SubhashSuperStore.in
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        The collection, storage and use of information related to you
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        We may automatically store certain information including but not limited
        to information about your web browser, IP address, cookies stored on
        your device and other information about you based upon your behaviour on
        the website. We use this information to do internal research on our
        users’ demographics, interests and behaviour to better understand,
        protect and serve our users. This information is compiled and analyzed
        on an aggregated basis. This information may include the URL that you
        just came from (whether this URL is on the website or not), which URL
        you next go to (whether this URL is on the website or not), your
        computer browser information, your IP address, and other information
        associated with your interaction with the website. We also collect and
        store personal information provided by you from time to time on the
        website/app. We only collect and use such information from you that we
        consider necessary for achieving a seamless, efficient and safe
        experience, customized to your needs including: • To enable the
        provision of services opted by you; • To communicate necessary account
        and product/service related information from time to time; • To allow
        you to receive quality customer care services; • To undertake necessary
        fraud and money laundering prevention checks, and comply with the
        highest security standards; • To comply with applicable laws, rules and
        regulations; and • To provide you with information and offers on
        products and services, on updates, on promotions, on related, affiliated
        or associated service providers and partners, that we believe would be
        of interest to you We also do use your contact information to send you
        notifications based on the tasks allotted to you and also based on your
        interests and prior activity. The Company may also use contact
        information internally to direct its efforts for product improvement, to
        contact you as a survey respondent, to notify you if you win any
        contest; and to send you promotional materials from its contest sponsors
        or advertisers. Contacts Permissions: If you allow us to access your
        contact list information including you and your contacts’ e-mail ids and
        any other details saved therein, it enables us to provide social
        features to you and your friends/contacts such as sharing your orders
        with your friends/contacts, inviting your contacts to try our app, send
        across referral links to your friends/contacts, etc. We may also use
        this information to make recommendations for groceries. This information
        will be synced from your phone and stored on our servers. Further, you
        may from time to time choose to provide payment related financial
        information (credit card, debit card, bank account details, name,
        billing address, shipping address, phone number etc.) on the website. We
        are committed to keeping all such sensitive data/information safe at all
        times and ensure that such data/information is only transacted over
        secure websites of approved payment gateways which are digitally
        encrypted and provide the highest possible degree of care available
        under the technology presently in use. The Company will not use your
        financial information for any purpose other than to complete a
        transaction with you. Additionally, your information may be used to
        communicate with you, screen orders for potential risk or fraud, use of
        preferences related information for advertising purposes and site
        optimization. Device information: We may need your device permission to
        get information about your device, like OS (operating system) name,
        mobile network, hardware model, unique device identifier, etc. Based on
        these inputs, we intend to optimize your order experience and thereby
        serve you better. Location: This permission enables us to give you the
        benefit of delivering the product to your address and also to provide
        location specific deals with personalized in-funnel experience. With
        Other Users: If you are a delivery partner, this permission enables us
        to verify your exact location information and locate your present
        location to align you at the preferred region when you begin or engage
        in a delivery and track the progress and completion of your trips. This
        feature/permission helps in connecting the right delivery partner with
        the right delivery opportunity and the path helps in computing the right
        distance pay along with providing consumers visibility of delivery
        partners location during delivery for address interventions. SMS: If you
        allow us to access your SMS, we read your SMS to autofill or
        pre-populate ‘OTP’ while making a transaction and to validate your
        mobile number. This provides you a seamless purchase experience while
        making an order and you do not need to move out of the app to read the
        SMS and then enter it in the app. Phone: The app requires access to make
        phone calls so that you can make phone calls to our customer contact
        centres directly through the app. Email ID: If you allow us to access
        your email id then, it enables us to send various deals and offers
        directly to your email. Photo/ Media/ Files: We take media storage
        permissions to cache the SubhashSuperStore images to provide seamless
        and fast experience while browsing SubhashSuperStore app. Wi-Fi
        connection information: When you allow us the permission to detect your
        Wi-Fi connection, we optimize your experience such as more detailing on
        maps, better image loading etc. Camera: You can grant camera permission
        as well to take pictures from your camera like that of the invoice,
        defective products etc., in our customer chat feature. You also
        explicitly agree and permit SubhashSuperStore to share any data related
        to you with its affiliates and/or the regulatory authorities, if
        required under any law. To the extent possible, we provide you the
        option of not divulging any specific information that you wish for us
        not to collect, store or use. You may also choose not to use a
        particular service or feature on the website/application and opt out of
        any non-essential communications from the Company. Further, transacting
        over the internet has inherent risks which can only be avoided by
        following security practices yourself, such as not revealing
        account/login related information to any other person and informing our
        customer care team about any suspicious activity or where your account
        has/may have been compromised. The Company uses data collection devices
        such as “cookies” on certain pages of the website to help analyse our
        web page flow, measure promotional effectiveness, and promote trust and
        safety. “Cookies” are small data files placed on your hard drive or
        device that assist us in providing our services and may include an
        anonymous unique identifier. The Company offers certain features that
        are only available through the use of a “cookie”. The Company also uses
        cookies to allow you to enter your password less frequently during a
        session. Cookies can also help the Company provide information that is
        targeted to your interests. Most cookies are “session cookies,” meaning
        that they are automatically deleted from your hard drive at the end of a
        session. You are always free to decline our cookies if your browser
        permits, although in that case you may not be able to use certain
        features on the website and you may be required to re-enter your
        password more frequently during a session. The Company does not retain
        any information collected for any longer than is reasonably considered
        necessary by us, or such period as may be required by applicable laws.
        The Company may be required to disclose any information that is lawfully
        sought from it by a judicial or other competent body pursuant to
        applicable laws. The website may contain links to other websites. We are
        not responsible for the privacy practices of such websites which we do
        not manage and control.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Choices available regarding collection, use and distribution of
        information
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        To protect against the loss, misuse and alteration of the information
        under its control, the Company has in place appropriate physical,
        electronic and managerial procedures. For example, the Company servers
        are accessible only to authorized personnel and your information is
        shared with employees and authorized personnel on a need to know basis
        to complete the transaction and to provide the services requested by
        you. Although the Company endeavours to safeguard the confidentiality of
        your personally identifiable information, transmissions made by means of
        the Internet cannot be made absolutely secure. By using the website, you
        agree that the Company will have no liability for disclosure of your
        information due to errors in transmission. Please note that the Company
        will not ask you to share any sensitive data or information via email or
        telephone. If you receive any such request by email or telephone, please
        do not respond/divulge any sensitive data or information and forward the
        information relating to the same to info@SubhashSuperStore.in for
        necessary action.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Misrepresentation
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        The Company shall assume no responsibility for any communication not
        authorised by SubhashSuperStore with respect to any offer, GOBD Sale
        etc. This includes, but is not limited to, any fraud or
        misrepresentation caused by any third-party administrators resulting in
        loss or injury to the user. Users are cautioned that SubhashSuperStore
        does not run any call centre, and does not make available for
        communication any landline/mobile number/email on the internet/social
        media/any other electronic means to its users other than the contact
        information detailed below. In case any user wishes to communicate, the
        user may communicate at the following number/email apart from the faster
        In app Chat option: Customer care helpline: 9696358522 Email:
        info@SubhashSuperStore.in Attention: Mr. ayush
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        You agree & confirm
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        • That in the event any product delivery is delayed or is returned from
        its destination on your account (e.g., providing the wrong name or
        address or other incorrect/misleading information), any additional costs
        incurred by the Company for re-delivery of the product(s) shall be borne
        by you • That while the Company makes best endeavors to ensure that
        Products are delivered to you within the period reflected at the time of
        placing the order, the Company does not make any representation,
        guarantee or warranty in relation to the delivery time as the estimated
        delivery time may be changed without notice and there could be delays
        for reasons beyond the reasonable control of the Company. Further you
        extinguish our responsibility if a request is made by you for unattended
        delivery • You agree to use the services provided by the Company for
        lawful purposes only, and comply with all applicable laws and
        regulations while using/accessing and transacting on the Marketplace •
        You will provide authentic and true information in all instances where
        any information is requested of you. The Company reserves the right to
        confirm and validate the information and other details provided by you
        at any point of time • If at any time, the information provided by you
        is found to be false or inaccurate (wholly or partly), the Company shall
        have the right in its sole discretion to reject registration, cancel all
        orders, and debar you from using its services and other affiliated
        services in the future without any prior intimation whatsoever, and
        without any liability to you • You are accessing the services made
        available by the Company and transacting at your sole risk and are using
        your best and prudent judgement before entering any transaction through
        the Marketplace • Before placing an order, you acknowledged to have
        checked and reviewed the product description carefully and in sufficient
        detail. By placing an order on the Marketplace, you agree to be bound by
        the conditions of sale included in the item’s description without
        exception • Notwithstanding the right of the Company on cancellation of
        the order, any Product in the order may be replaced by the Company in
        case of unavailability or for other reasons outside the control of the
        Company, subject to you may refuse to accept such replacement/substitute
        product. For such cancellations, the Company will initiate a refund, if
        any, on the order/product, if any, made by you under the relevant order
        within 72 hours
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        GST effect on delivery
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        • As our nation welcomes the ‘One Nation, One Tax’’ initiative,
        effective from July 1st 2017, we, at SubhashSuperStore, also welcome
        this change by making our processes fully compliant with GST • Your
        orders will be fulfilled as usual and in line with the GST guidelines •
        What are the changes you can expect at our end?: Since the government
        has exempted certain goods from GST, you will receive invoices for them
        separately. Which means that your order will now be broken down into 3
        components: • At the delivery, our field executive will hand over a
        delivery statement, listing down all the items you have ordered • A
        Delivery Statement for you to check all the items you’ve ordered • A Tax
        Invoice showing all the items that have been taxed • A Bill of Supply We
        assure you that you will receive all the GST benefits and lower priced
        items, as always from SubhashSuperStore. If you still have any
        questions, please feel free to reach out to us at our Customer Care
        helpline: 9696358522
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        GST effect on delivery
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        • As our nation welcomes the ‘One Nation, One Tax’’ initiative,
        effective from July 1st 2017, we, at SubhashSuperStore, also welcome
        this change by making our processes fully compliant with GST • Your
        orders will be fulfilled as usual and in line with the GST guidelines •
        What are the changes you can expect at our end?: Since the government
        has exempted certain goods from GST, you will receive invoices for them
        separately. Which means that your order will now be broken down into 3
        components: • At the delivery, our field executive will hand over a
        delivery statement, listing down all the items you have ordered • A
        Delivery Statement for you to check all the items you’ve ordered • A Tax
        Invoice showing all the items that have been taxed • A Bill of Supply We
        assure you that you will receive all the GST benefits and lower priced
        items, as always from SubhashSuperStore. If you still have any
        questions, please feel free to reach out to us at our Customer Care
        helpline: 9696358522
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Order cancellation
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        • As a user, you may cancel an order placed by you before the order
        enters the “billed” stage. For any permitted cancellations, we will
        initiate a refund, if any, made by you under the relevant order within
        approximately 48-72 hours. • Orders may be cancelled by the Company in
        any of the following cases: (a) if it is suspected that a customer has
        undertaken a fraudulent transaction, or (b) if it is suspected that a
        customer has undertaken a transaction which does not conform to or
        violates these Terms of Use/User Agreement, or (c) in case of
        unavailability of product(s), or (d) for any reason outside the control
        of the Company, including causes for delivery-related logistical
        difficulties. For such cancellations, the Company will initiate a
        refund, if any, made by you under the relevant order within 72 hours. •
        Further, while precautions are taken to ensure accuracy of product
        specifications, the details of a product as reflected on the Marketplace
        may be inaccurate due to technical issues, typographical errors or
        incorrect product information provided to the Company.You shall be
        notified as soon as such error comes to the notice of the Company. In
        such an event, the Company reserves the right to cancel your order and
        grant credit/coupon for the value of the returned product. Such credit
        shall be available for use against a subsequent invoice for transactions
        on the Marketplace. • We maintain a list of all fraudulent transactions
        and non-complying users, and reserve the right to deny access to such
        users at any time or cancel any orders placed by them in future.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Returns & refunds
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        The Company will accept the return of Products only in case the same are
        damaged, defective or incorrectly delivered and after the expiry of
        following timelines no returns will be accepted by the Company: o For
        books & boat category Products, within (twenty four) 24 hours from the
        date of delivery of the order. o Other Products, within (seventy two) 72
        hours from the date of delivery of the order. ·All refunds for returns
        and cancellations will be processed within seven (7) days from the date
        of return of the Product subject to satisfactory checks and the refunds
        shall be processed in the same manner as they are received, unless
        refunds have been provided to You in the form of credits, refund amount
        will reflect in Your account based on respective banks policies. Refunds
        for Products purchased on cash on delivery basis shall be refunded by
        way of promotional codes which shall expire after thirty (30) days from
        the date of issue of such promotional codes. ·All other promotional
        codes issued by Company to Customers shall expire within seven (7) days
        from the date of its issue. ·Certain Products may not be eligible for
        return and will be marked “non-returnable” at the relevant page
        displaying such Products such as perfumes, deodorants, intimate wear.
        The Customers should check and verify such Products at the time of
        collecting the order as we will not be able to accept any complaints for
        those Products, once they are delivered. ·Unless otherwise stated and/or
        as mutually agreed between the Company and Customer, for payments made
        through electronic means such as debit card, credit card, net banking,
        wallet etc. the refund shall be made using the same payment mode. ·All
        refunds shall be made in Indian Rupees only.
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>UPI</h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        Unified Payments Interface (UPI) is an instant real-time payment system
        developed by National Payments Corporation of India facilitating
        inter-bank transactions. The interface is regulated by the Reserve Bank
        of India and works by instantly transferring funds between two bank
        accounts on a mobile platform 1. UPI is built over Immediate Payment
        Service (IMPS) for transferring funds 2. UPI would appear as a separate
        tab on the Payment Options page on your android application 3. Once you
        click on the UPI option, it will scan your device to find out the UPI
        apps on your phone 4. You can choose one of the apps to pay for the
        order and complete the transaction Refund against the transaction (if
        any) would be reflected in your respective account within 5-7 working
        days post initiation
      </p>
      <h3 style={{ textAlign: "center", paddingBottom: "40px" }}>
        Printing as a service
      </h3>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "40px" }}>
        • For availing the printing services, Customers have to upload the
        paper, file, data, text, image etc. in printable format (“Document(s)”
        on the Marketplace). Upon receiving Your request, the Document(s) will
        be printed and delivered to You. • You agree not to upload any Document
        which is illegal, libelous, prohibited, obscene, pornographic or
        problematic material or a Document that encourages conduct which
        constitutes a criminal offence, results in civil liability or otherwise
        breaches any applicable laws or regulations. • While We endeavour to
        protect the confidentiality of Documents uploaded, We take no
        responsibility to maintain the confidentiality of your Documents.
        Customer shall upload the Document(s) at their own risk. You are
        accordingly advised not to upload the Documents which You may consider
        confidential. • We do not store any Document(s). Post printing the
        Document(s), the Document(s) are automatically deleted from Our servers.
        • You will be charged a printing fee, delivery charges, surge charges,
        packaging charges, convenience charges etc. which will be reflected at
        the time of availing the printing services. The applicable fee will be
        notified to You prior to placing an order for the printing services. By
        placing the order you agree to pay the fee and charges as reflected
        prior to placing of the order. • Customer will have to make payment in
        advance for availing printing services. • We reserve the right to change
        the fee at any time. • We reserve the right to share the Document(s)
        uploaded by the Customer for availing printing services with our
        affiliated stores for printing and delivery purposes. • If the Customer
        finds any defects in the printouts which Customer have received, then
        the Customer shall immediately send the pictures of the defective
        printout with the order number to the Customer Care. Any complaints
        received after 24 hours of the delivery of the printouts will not be
        entertained, however, We will try to find the best solution to address
        such complaints. • The Customer agrees that the Customer will only use
        the printing services for lawful purposes and comply with all applicable
        laws and regulations. • The Customer agrees that the act of uploading
        the Document(s) indicates the Customer’s consent to the collection,
        storage, retention and usage of such information for the purpose for
        which it has been uploaded. • The Customer agrees that the printing
        services offered on the Marketplace are being availed by the Customer at
        their own risk. It is implied that they are using their best and prudent
        judgement before entering into any transaction through the Marketplace.
        single app for all your daily needs order online on SubhashSuperStore.
        Lucknow
      </p>
      <Footer />
    </div>
  );
};

export default TermsConditions;
