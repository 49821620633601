import React from "react";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        marginTop: "170px",
        textAlign: "justify",
        padding: "20px",
      }}
    >
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Privacy policy
      </h2>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "150px" }}>
        We i.e. "Subhash Super Store are committed to protecting the privacy and
        security of your personal information. Your privacy is important to us
        and maintaining your trust is paramount. This privacy policy explains
        how we collect, use, process and disclose information about you. By
        using our website/ app/ platform and services, you consent to the terms
        of our privacy policy (“Privacy Policy”) in addition to our ‘Terms of
        Use.’ We encourage you to read this privacy policy to understand the
        collection, use, and disclosure of your information from time to time,
        to keep yourself updated with the changes and updates that we make to
        this policy. This privacy policy describes our privacy practices for all
        websites, products and services. We recommend that you read the privacy
        policy on the applicable site. Should you have any clarifications
        regarding this privacy policy, please write to us at
        info@SubhashSuperStore.in
      </p>
      <br />
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Choices available regarding the collection, use and distribution of
        information
      </h2>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "150px" }}>
        To protect against the loss, misuse and alteration of the information
        under its control, the Company has in place appropriate physical,
        electronic and managerial procedures. For example, the Company servers
        are accessible only to authorized personnel and your information is
        shared with employees and authorized personnel on a need to know basis
        to complete the transaction and to provide the services requested by
        you. Although the Company endeavours to safeguard the confidentiality of
        your personally identifiable information, transmissions made by means of
        the Internet cannot be made absolutely secure. By using the website, you
        agree that the Company will have no liability for disclosure of your
        information due to errors in transmission. We may share certain
        information with governmental agencies, where we are: • Obligated under
        the applicable laws or in good faith to respond to court orders and
        processes; or • Detecting and preventing against actual or potential
        occurrence of identity theft, fraud, abuse of Services and other illegal
        acts; • Under a duty to disclose or share your personal data in order to
        enforce our Terms of Use and other agreements, policies or to protect
        the rights, property, or safety of the Company, our customers, or
        others, or in the event of a claim or dispute relating to your use of
        our Services. This includes exchanging information with other companies
        and organisations for the purposes of fraud detection and credit risk
        reduction. Please note that the Company will not ask you to share any
        sensitive data or information via email or telephone. If you receive any
        such request by email or telephone, please do not respond/divulge any
        sensitive data or information and forward the information relating to
        the same to info@SubhashSuperStore.in for necessary action.
      </p>
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        The collection, storage and use of information related to you
      </h2>
      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "150px" }}>
        We may automatically store certain information including but not limited
        to information about your web browser, IP address, cookies stored on
        your device and other information about you based upon your behaviour on
        the website. We use this information to do internal research on our
        users’ demographics, interests and behaviour to better understand,
        protect and serve our users. This information is compiled and analyzed
        on an aggregated basis. This information may include the URL that you
        just came from (whether this URL is on the website or not), which URL
        you next go to (whether this URL is on the website or not), your
        computer browser information, your IP address, and other information
        associated with your interaction with the website. We also collect and
        store personal information provided by you from time to time on the
        website/app. We only collect and use such information from you that we
        consider necessary for achieving a seamless, efficient and safe
        experience, customized to your needs including: • To enable the
        provision of services opted by you; • To communicate necessary account
        and product/service related information from time to time; • To allow
        you to receive quality customer care services; • To undertake necessary
        fraud and money laundering prevention checks, and comply with the
        highest security standards; • To comply with applicable laws, rules and
        regulations; and • To provide you with information and offers on
        products and services, on updates, on promotions. We also do use your
        contact information to send you notifications based on the tasks
        allotted to you and also based on your interests and prior activity. The
        Company may also use contact information internally to direct its
        efforts for product improvement, to contact you as a survey respondent.
        Contacts Permissions: If you allow us to access your contact list
        information including you and your contacts’ e-mail ids and any other
        details saved therein, it enables us to provide social features to you
        and your friends/contacts such as sharing your orders with your
        friends/contacts, inviting your contacts to try our app, send across
        referral links to your friends/contacts, etc. We may also use this
        information to make recommendations for groceries. This information will
        be synced from your phone and stored on our servers. Further, you may
        from time to time choose to provide payment related financial
        information (credit card, debit card, bank account details, name,
        billing address, shipping address, phone number etc.) on the website. We
        are committed to keeping all such sensitive data/information safe at all
        times and ensure that such data/information is only transacted over
        secure websites of approved payment gateways which are digitally
        encrypted and provide the highest possible degree of care available
        under the technology presently in use. The Company will not use your
        financial information for any purpose other than to complete a
        transaction with you. Additionally, your information may be used to
        communicate with you, screen orders for potential risk or fraud, use of
        preferences related information for advertising purposes and site
        optimization. Device information: We may need your device permission to
        get information about your device, like OS (operating system) name,
        mobile network, hardware model, unique device identifier, etc. Based on
        these inputs, we intend to optimize your order experience and thereby
        serve you better. Location: This permission enables us to give you the
        benefit of delivering the product to your address and also to provide
        location specific deals with personalized in-funnel experience.. SMS: If
        you allow us to access your SMS, we read your SMS to autofill or
        pre-populate ‘OTP’ while making a transaction and to validate your
        mobile number. This provides you a seamless purchase experience while
        making an order and you do not need to move out of the app to read the
        SMS and then enter it in the app. Phone: The app requires access to make
        phone calls so that you can make phone calls to our customer contact
        centres directly through the app. Email ID: If you allow us to access
        your email id then, it enables us to send various deals and offers
        directly to your email. Photo/ Media/ Files: We take media storage
        permissions to cache the Subhash Super Store images to provide seamless
        and fast experience while browsing Subhash Super Store app. Wi-Fi
        connection information: When you allow us the permission to detect your
        Wi-Fi connection, we optimize your experience such as more detailing on
        maps, better image loading etc. Camera: You can grant camera permission
        as well to take pictures from your camera like that of the invoice,
        defective products etc., in our customer chat feature. To the extent
        possible, we provide you the option of not divulging any specific
        information that you wish for us not to collect, store or use. You may
        also choose not to use a particular service or feature on the
        website/application and opt out of any non-essential communications from
        the Company. Further, transacting over the internet has inherent risks
        which can only be avoided by following security practices yourself, such
        as not revealing account/login related information to any other person
        and informing our customer care team about any suspicious activity or
        where your account has/may have been compromised. The Company uses data
        collection devices such as “cookies” on certain pages of the website to
        help analyse our web page flow, measure promotional effectiveness, and
        promote trust and safety. “Cookies” are small data files placed on your
        hard drive or device that assist us in providing our services and may
        include an anonymous unique identifier. The Company offers certain
        features that are only available through the use of a “cookie”. The
        Company also uses cookies to allow you to enter your password less
        frequently during a session. Cookies can also help the Company provide
        information that is targeted to your interests. Most cookies are
        “session cookies,” meaning that they are automatically deleted from your
        hard drive at the end of a session. You are always free to decline our
        cookies if your browser permits, although in that case you may not be
        able to use certain features on the website and you may be required to
        re-enter your password more frequently during a session. The Company
        does not retain any information collected for any longer than is
        reasonably considered necessary by us, or such period as may be required
        by applicable laws. The Company may be required to disclose any
        information that is lawfully sought from it by a judicial or other
        competent body pursuant to applicable laws. The website may contain
        links to other websites. We are not responsible for the privacy
        practices of such websites which we do not manage and control
      </p>
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Changes to the privacy policy
      </h2>

      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "150px" }}>
        Subhash Super Store reserves the right to change this policy from time
        to time. Any changes shall be effective immediately upon the posting of
        the revised Privacy Policy. We encourage you to periodically review this
        page for the latest information on our privacy practices
      </p>

      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Privacy questions and access to your personal information
      </h2>

      <p style={{ width: "80%", margin: "0 auto", paddingBottom: "150px" }}>
        If you have questions, concerns, or suggestions regarding our privacy
        policy, we can be reached using the contact information on our “Contact
        Us” page or at info@SubhashSuperStore.in In certain cases, you may have
        the ability to view or edit your personal information online. In the
        event your information is not accessible online and you wish to obtain a
        copy of particular information you provided to us, or if you become
        aware the information is incorrect and you would like us to correct it,
        please contact us immediately Before we are able to provide you with any
        information or correct any inaccuracies, however, we may ask you to
        verify your identity and to provide other details to ascertain your
        identity and to help us to respond to your request. We will contact you
        within 30 days of your request single app for all your daily needs order
        online on Subhash Super Store. City we currently serve: Lucknow
      </p>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
