import React from "react";
import { Link } from "react-router-dom";
import "./BestSellerCard.css";

const BestSellerCard = ({ product }) => {
  const discount = (product?.discount / 100) * product.sell_price;
  const newprice = Number(product.sell_price - discount).toFixed(2);
  const imgsrc = `https://groceryapp-s3bucket.s3.ap-south-1.amazonaws.com/${product?._id}.png`;

  return (
    <Link
      className="productCard1"
      to={`/product/${product?.groupId}`}
      state={{ Data: product }}
    >
      {product?.countInStock < 1 ? (
        <>
          <img src={imgsrc} alt={product.name} style={{ opacity: "0.4" }} />
          <p
            style={{
              position: "absolute",
              alignSelf: "center",
              top: 50,
              color: "gray",
              fontWeight: "600",
              left: 50,
              fontSize: 18,
            }}
          >
            Out of stock
          </p>
        </>
      ) : (
        <img src={imgsrc} alt={product.name} />
      )}
      {product?.discount > 0 && (
        <div className="deal-container">
          {Math.round(product?.discount * 100) / 100}% OFF
          {/* {Number(product?.discount).toFixed(2)}% OFF */}
        </div>
      )}
      <p>{product.name}</p>

      {discount > 0 ? (
        <>
          <span
            style={{
              fontSize: "20px",
            }}
          >
            ₹ {`${newprice}`}
          </span>

          <span
            style={{
              textDecoration: "line-through",
              color: "grey",
              fontSize: "18px",
            }}
          >
            ₹ {`${product.sell_price}`}{" "}
          </span>
        </>
      ) : (
        <span
          style={{
            fontSize: "15px",
          }}
        >
          ₹ {`${product.sell_price}`}{" "}
        </span>
      )}
    </Link>
  );
};

export default BestSellerCard;
