import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import { mobile } from "../responsive";
import apiClient from "./../api/client";
import swal from "sweetalert";
import useAuth from "../auth/useAuth";
import PropagateLoader from "react-spinners/PropagateLoader";
import styled from "styled-components";
import Footer from "./../components/Footer";
import OtpInput from "react-otp-input";

const VerifyOTP = () => {
  const [uploadVisible, setUploadVisible] = useState(false);
  const { state } = useLocation();

  const userData = state?.user;
  const modal = state?.modal;
  const [name, setName] = useState("");
  const userMobile = userData?.mobile;
  const userOtp = userData?.code;
  const { logIn } = useAuth();
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const handleSuccess = async () => {
    setUploadVisible(true);
    //clear verification code from user model

    const res = await apiClient.put("/users/profile", {
      id: userData?._id,
      name,
    });

    const result = await apiClient.post("/users/clearVerificationCode", {
      id: userData?._id,
    });

    if (result.ok) {
      logIn(result.data.token);
      setUploadVisible(false);
      navigate("/");
    }
  };

  return (
    <>
      <Container>
        <Navbar />
        {uploadVisible ? (
          <PropagateLoader color="rgba(54, 215, 183, 1)" size={30} />
        ) : (
          <Wrapper>
            <Title>Verify Mobile Number</Title>
            <p
              style={{ textAlign: "center", marginTop: "20px", color: "gray" }}
            >
              Please check the OTP sent to your mobile number
            </p>
            <Row>
              <p
                style={{
                  fontWeight: 500,
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                {userMobile}
              </p>
              <ChangeBtn onClick={() => navigate(-1)}>Change</ChangeBtn>
            </Row>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{ width: "10%", margin: "0 auto" }}
              shouldAutoFocus={true}
            />
            <Button
              onClick={() => {
                if (otp === userOtp) {
                  handleSuccess();
                } else {
                  alert("Opps", "Invalid code!");
                }
              }}
            >
              CONTINUE
            </Button>
          </Wrapper>
        )}
      </Container>
    </>
  );
};
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChangeBtn = styled.button`
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  margin-left: 10px;
`;

const Wrapper = styled.div`
  width: 26%;
  min-height: 50vh;
  padding: 20px;
  background-color: #fcfcfc;
  box-shadow: 0 1px 0 0 #ccc;
  border: 1px solid #eee;
  border-radius: 10px;
  ${mobile({ width: "70%" })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  background-color: #ef6c00;
  color: white;
  cursor: pointer;
  border-radius: 15px;
  margin: 0 auto;
  margin-top: 50px;
  font-weight: 700;
`;

const Link = styled.a`
  /* margin: 25px 0px; */
  font-size: 17px;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;
  color: black;
`;

export default VerifyOTP;
