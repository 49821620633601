import "./SearchResultsList.css";
import { SearchResult } from "./SearchResult";

export const SearchResultsList = ({ results }) => {
  return (
    <div className="results-list">
      {results.map((result, id) => {
        const imgsrc = `https://groceryapp-s3bucket.s3.ap-south-1.amazonaws.com/${result?._id}.png`;
        return <SearchResult result={result} key={id} imgsrc={imgsrc} />;
      })}
    </div>
  );
};
