import React, { useState } from "react";
import "./saveadd.css";
import useAuth from "../../auth/useAuth";
import swal from "sweetalert";
import apiClient from "../../api/client";
import { useNavigate } from "react-router-dom";

const SaveAddress = () => {
  const { user, logIn } = useAuth();
  const navigate = useNavigate();
  const [address, setAddress] = useState(
    user ? user.shippingAddress.address : ""
  );
  const [street, setStreet] = useState(
    user ? user?.shippingAddress?.street : ""
  );

  const [pincode, setPincode] = useState(
    user ? user?.shippingAddress?.pincode : null
  );
  const [region, setRegion] = useState(user ? user.shippingAddress?.area : "");
  const [landmark, setLandmark] = useState(
    user ? user?.shippingAddress?.landmark : ""
  );

  const [phoneNo, setPhoneNo] = useState(
    user ? user.shippingAddress.mobileNumber : ""
  );
  const [email, setEmail] = useState(user ? user.shippingAddress.email : "");

  const shippingSubmit = async (e) => {
    e.preventDefault();

    if (phoneNo.length < 10 || phoneNo.length > 10) {
      swal("Phone Number should be 8 digits without country code");
      return;
    }

    //save shipping add in user model
    const { data } = await apiClient.post("/users/saveshippingaddress", {
      userId: user.id,
      shippingAddress: {
        address,
        street,
        area: region,
        email,
        pincode,
        landmark,
        mobileNumber: phoneNo,
      },
    });

    logIn(data.token);
    navigate("/");
  };

  return (
    <>
      <div className="shippingContainer">
        <div className="shippingBox">
          <h3 className="shippingHeading">Save Address</h3>

          <form
            className="shippingForm"
            encType="multipart/form-data"
            onSubmit={shippingSubmit}
          >
            <div>
              <input
                type="text"
                placeholder="House No."
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Block/Street"
                required
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Area/Locality"
                required
                value={region}
                onChange={(e) => setRegion(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Landmark"
                required
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Pincode"
                required
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </div>

            <div>
              <input
                type="mobile"
                placeholder="Phone Number"
                required
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                size="8"
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email Address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="10"
              />
            </div>
            <input
              type="submit"
              value="Continue"
              className="shippingBtn"
              disabled={address ? false : true}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default SaveAddress;
