import React from "react";
import { useLocation } from "react-router-dom";
import SubBrand from "../components/SubBrand";

const AllSubBrandsPage = () => {
  const { state } = useLocation();
  const subBrands = state?.data;
  // console.log(subBrands[0], subBrands);
  return (
    <div style={{ marginTop: "130px" }}>
      <h1 className="productsHeading">
        Sub Brands of {state?.item?.name}
        <img src={state?.item?.photo} />
      </h1>
      <SubBrand data={subBrands} />
    </div>
  );
};

export default AllSubBrandsPage;
