import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  flex: 1;
  height: 28vh;
  margin: 5px 20px;
  position: relative;
  min-width: 180px;
  border: 0.7px solid gray;
  border-radius: 3px;
  ${mobile({
    margin: "0 10px",
    height: "23vh",
    minWidth: "140px",
    display: "inline-block",
    verticalAlign: "middle",
  })}

  &:hover {
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
    transition: 0.4s;
    transform: translateY(-1vmax);
  }
`;
const Image = styled.img`
  width: 100%;
  height: 72%;
  object-fit: contain;

  ${mobile({
    height: "91%",
    width: "100%",
    borderRadius: "21%",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  })}
`;
const Info = styled.div`
  position: absolute;
  width: 100%;
  /* height: 10vh; */
  margin: 0 auto;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fcf5f5;
  /* border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px; */
  ${mobile({ height: "4vh" })}
`;
const Title = styled.p`
  color: black;
  margin-bottom: 10px;
  text-align: center;
  ${mobile({ fontSize: "9px", marginBottom: "1px" })}
`;

const CategoryItem = ({ item }) => {
  const navigate = useNavigate();
  return (
    <Container onClick={() => navigate("/category", { state: item })}>
      <Image src={item.photo} />
      <Info>
        <Title>{item.name}</Title>
      </Info>
    </Container>
  );
};

export default CategoryItem;
