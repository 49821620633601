import React, { Fragment, useState, useEffect } from "react";
import "./Payment.css";
import { useNavigate, useLocation } from "react-router-dom";
import MetaData from "../../components/MetaData";
import CheckoutSteps from "./CheckoutSteps";
import { Button } from "@mui/material";
import apiClient from "./../../api/client";
import useAuth from "./../../auth/useAuth";
import useRazorpay from "react-razorpay";
import animationData from "../../lottie/delivery.json";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import Lottie from "react-lottie";
import { clear } from "../../redux/cartSlice";
import { useSearchParams } from "react-router-dom";
import { add, orderclear } from "../../redux/orderSlice";

import animationData2 from "../../lottie/payment-processing.json";

const Payment = () => {
  const { state } = useLocation();
  const [Razorpay] = useRazorpay();

  const {
    shippingAddress,
    orderItems,
    subTotal,
    slot,
    data,
    couponCode,
    deliveryCharge,
    deliveryDate,
  } = state;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationData2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const dispatch = useDispatch();
  const [uploadVisible, setUploadVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState();
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const { user } = useAuth();

  const options = {
    description: "Total Payment",
    image: "https://groceryapp-s3bucket.s3.ap-south-1.amazonaws.com/logo.png",
    currency: "INR",
    key: data?.notes.key,
    amount: subTotal,
    name: "Subhash Super Store",
    order_id: data?.id,
    prefill: {
      email: user?.shippingAddress?.email,
      contact: user?.phone,
      name: user?.name,
    },
    theme: { color: "#53a20e" },
    handler: async (res) => {
      try {
        const paymentId = res.razorpay_payment_id;
        if (paymentId) {
          setPaymentStatus(true);
        }
      } catch (error) {
        setUploadVisible(false);
        createFailedOrder();
      }
    },
  };

  const rzp1 = new Razorpay(options);

  useEffect(() => {
    if (paymentStatus === true) {
      createOrder();
    }
  }, [paymentStatus]);

  useEffect(() => {
    dispatch(orderclear());
  }, []);

  var taxPrice = 0;
  var shippingPrice = 0;
  var totalPrice = (taxPrice + Number(subTotal)).toFixed(2);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setUploadVisible(true);

    const result = await apiClient.post("/orders/create-order", {
      orderItems,
      shippingAddress,
      paymentMethod: "COD",
      itemsPrice: subTotal,
      totalPrice: subTotal,
      deliveryStatus: "Processing",
      userId: user.id,
      deliverySlot: slot,
      deliveryCharge,
      deliveryDate,
    });
    if (result.ok) {
      swal("Order Placed!");
      dispatch(clear());
      dispatch(orderclear());
      navigate("/myorders");
    }

    setUploadVisible(false);
  };

  const handlePay = async () => {
    setUploadVisible(true);
    let flag;

    for (let i = 0; i < orderItems?.length; i++) {
      const { data } = await apiClient.get("/products/getproductbyid", {
        productId: orderItems[i]?.product,
      });

      if (orderItems[i]?.qty > data?.countInStock) {
        flag = true;
      }
    }

    if (checked1 && !flag) {
      codOrder();
    } else if (!flag) {
      // rzp1.on("payment.failed", function (response) {
      //   setUploadVisible(false);
      //   createFailedOrder();
      // });
      rzp1.open();
    } else {
      setUploadVisible(false);
      alert("Error!", "Product you added is out of stock currently.");
      dispatch(clear());
      // navigation.navigate('HomeScreen');
    }
  };

  const createOrder = async () => {
    const result = await apiClient.post("/orders/create-order", {
      orderItems,
      shippingAddress,
      paymentMethod: "Online",
      itemsPrice: subTotal,
      totalPrice: subTotal,
      deliveryStatus: "Processing",
      userId: user.id,
      deliverySlot: slot,
      isPaid: true,
      deliveryCharge,
      deliveryDate,
    });
    if (result.ok) {
      const result = await apiClient.post("/coupen/used", {
        userId: user?.id,
        coupenId: couponCode,
      });
      setUploadVisible(false);
      // Alert.alert('Order Placed!', 'Hurray! we have received your order.');
      swal("Order Placed!");
      dispatch(clear());
      dispatch(orderclear());
      navigate("/myorders");
    } else {
      swal("Error Retry");
      setUploadVisible(false);
    }
  };

  const createFailedOrder = async () => {
    const result = await apiClient.post("/orders/create-order", {
      orderItems,
      shippingAddress,
      paymentMethod: "Online",
      itemsPrice: subTotal,
      totalPrice: subTotal,
      deliveryStatus: "Cancelled",
      userId: user.id,
      deliverySlot: slot,
      isPaid: false,
      deliveryCharge,
      deliveryDate,
    });
    if (result.ok) {
      swal("Payment Failed");
      setUploadVisible(false);
      dispatch(clear());
    } else {
      swal("Error Retry");
      setUploadVisible(false);
    }
  };

  const codOrder = async () => {
    const result = await apiClient.post("/orders/create-order", {
      orderItems,
      shippingAddress,
      paymentMethod: "COD",
      itemsPrice: subTotal,
      totalPrice: subTotal,
      deliveryStatus: "Processing",
      userId: user.id,
      deliverySlot: slot,
      deliveryCharge,
      deliveryDate,
    });
    if (result.ok) {
      const result = await apiClient.post("/coupen/used", {
        userId: user?.id,
        coupenId: couponCode,
      });

      dispatch(clear());
      alert("Order Placed!", "Hurray! we have received your order.");

      // navigation.reset({
      //   index: 0,
      //   routes: [{name: 'OrdersList'}],
      // });
      setUploadVisible(false);
    } else {
      swal("Error Retry");
      setUploadVisible(false);
    }
  };
  return (
    <Fragment>
      {modal ? (
        <div
          style={{
            marginTop: "200px",
          }}
        >
          <Lottie options={defaultOptions2} height={200} width={200} />
          <h5 style={{ textAlign: "center" }}>
            Payment Processing... please wait
          </h5>
        </div>
      ) : (
        <>
          <MetaData title="Payment" />
          <CheckoutSteps activeStep={2} />
          {uploadVisible ? (
            <div style={{ width: "100%", margin: "0 auto" }}>
              <Lottie options={defaultOptions} height={400} width={400} />
            </div>
          ) : (
            <div className="payment-container">
              <div className="box1">
                <div className="delivery-wrapper">
                  <h5>1. Delivery address</h5>
                  <br />
                  <div className="info">
                    <p>To: {user.name}</p>
                    <p>
                      Address: {shippingAddress.address},{" "}
                      {shippingAddress.street}, {shippingAddress.pincode}
                    </p>
                  </div>
                </div>
                <br />
                <div className="total-wrapper">
                  <h5>2. Order summary</h5>
                  <br />
                  <div className="info">
                    <h6>Order Total</h6>
                    <p> ₹ {totalPrice} /-</p>
                  </div>
                </div>
              </div>
              <div className="paymentContainer">
                <div>
                  <h5 style={{ marginBottom: "20px" }}>
                    3. Select a payment method
                  </h5>
                </div>
                <div className="btn-wrapper">
                  <div className="btn-container">
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      style={{
                        width: "260px",
                        backgroundColor: "rgb(12, 131, 31)",
                        fontWeight: "600",
                      }}
                    >
                      Cash on Delivery
                    </Button>
                  </div>
                  <div className="btn-container">
                    <Button
                      variant="outlined"
                      onClick={handlePay}
                      style={{
                        width: "260px",
                        backgroundColor: "rgb(12, 131, 31)",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Online/Card
                    </Button>
                  </div>{" "}
                  <div className="btn-container">
                    {/* <Button variant="outlined" onClick={status}>
                check status
              </Button> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Fragment>
  );
};

export default Payment;
