import React, { useEffect } from "react";
import Slider from "../components/Slider";
import Categories from "./../components/Categories";
import "./index.css";
import Title from "../components/Title";
import Footer from "../components/Footer";
import HomeProducts from "./Products/HomeProducts";
import BestSellers from "./Products/Best/BestSellers";
import SaleProducts from "./Products/Sale/SaleProducts";
import AquaProducts from "./Products/Aquarium/AquaProducts";
import PetFavourite from "./../components/PetFavourite";
import PopularCategories from "./../components/PopularCategories";
import Brand from "./../components/Brand";

const HomePage = () => {
  return (
    <div class="homepage-container">
      <Slider />
      <br />
      <Title title="Shop By Category" />
      <Categories />
      <br />
      <Title title="Discover Favourite's" />
      <PetFavourite />
      <br />
      {/* <Title title="Popular Categories" />
      <PopularCategories /> */}
      <br />
      <Title title="Shop by Brand" />
      <Brand />
      <br />
      <Title title="Best Sellers" />
      <BestSellers />

      <Title title="Top Offers" />
      <SaleProducts />

      <HomeProducts />

      <Footer />
    </div>
  );
};

export default HomePage;
