import { create } from "apisauce";

const apiClient = create({
  // baseURL: "http://localhost:5000/api",
  baseURL: "https://backend.subhashsuperstore.in/api",

  headers: { Accept: "application/vnd.github.v3+json" },
});

export default apiClient;
