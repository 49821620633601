import React, { useState } from "react";
import nft from "../../assets/logo.png";
import "./Modal.css";

const Modal = ({ open, onClose, onInput, onSubmit }) => {
  // const [loc, setLoc] = useState();
  if (!open) return null;

  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        {/* <img src={nft} alt="/" className="modalimg" /> */}
        <div className="modalRight">
          <p className="closeBtn" onClick={onClose}>
            X
          </p>
          <div className="content">
            <h3>Select your location</h3>
            <p>Our products and offers are location specific</p>
          </div>
          <div className="btnContainer">
            <input
              type="text"
              placeholder="Enter Area or Pincode"
              className="input2"
              onChange={(event) => onInput(event.target.value)}
            />
            <button className="btnPrimary" onClick={onSubmit}>
              <span className="bold">CHECK DELIVERY</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
