import { Add, Remove } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Footer from "../components/Footer";
import { mobile } from "../responsive";
// import Modal from "react-modal";
import { Modal } from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import {
  decrementQuantity,
  incrementQuantity,
  remove,
} from "../redux/cartSlice";
import { useEffect, useState } from "react";
import apiClient from "../api/client";
import useAuth from "../auth/useAuth";
import swal from "sweetalert";
import "./index.css";

const sumTotal = (products) => {
  return products.reduce((sum, item) => {
    return sum + parseFloat(item.newprice) * parseFloat(item.quantity);
  }, 0);
};

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    minHeight: "50vh",
    position: "relative",
  },
};

const Cart = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.cart.cart);
  const [charge, setCharge] = useState();
  const [limit, setLimit] = useState("");
  const [code, setCode] = useState("");
  const [codeId, setCodeId] = useState();
  const [list, setList] = useState();
  const [coupon, setCoupon] = useState(false);
  const [reset, setReset] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [t1, setT1] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);

  //modal2
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  let subTotalPrice = sumTotal(products).toFixed(2);
  let finalPrice = Number(t1).toFixed(2);

  useEffect(() => {
    fetch();
  }, [products, subTotalPrice, reset]);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const handleQuantity = (type, id) => {
    if (type === "dec") {
      //delete
      dispatch(decrementQuantity(id));
    } else {
      //add
      dispatch(incrementQuantity(id));
    }
  };

  const fetch = async () => {
    const { data } = await apiClient.get("/variations/get-deliverycharge");

    const upperlimit = data[0]?.limit;
    setLimit(upperlimit);
    const Charge = data[0]?.charge;

    if (Number(subTotalPrice) > Number(upperlimit)) {
      setCharge(0);
      // swal("Hurray", "Your delivery charge got waived off");
      if (discount && subTotalPrice >= appliedCoupon?.limit) {
        finalPrice =
          Number(subTotalPrice) -
          (Number(discount) / 100) * Number(subTotalPrice);

        setT1(finalPrice);
      } else {
        setDiscount(0);
        setCoupon(false);
        setCodeId();
        setCode("");
        setT1(subTotalPrice);
      }
    } else {
      setCharge(Charge);
      if (discount && subTotalPrice >= appliedCoupon?.limit) {
        finalPrice =
          Number(subTotalPrice) -
          (Number(discount) / 100) * Number(subTotalPrice) +
          Number(data[0]?.charge);

        setT1(finalPrice);
      } else {
        setDiscount(0);
        setCoupon(false);
        setCodeId();
        setCode("");
        setT1(
          Number((sumTotal(products) + Number(data[0]?.charge)).toFixed(2))
        );
      }
    }
  };

  const fetchCoupons = async () => {
    const { data } = await apiClient.get("/coupen/");

    setList(data);
  };

  const handleCoupon = (code1) => {
    const abc = list?.filter(function (el) {
      return el?.name === code1 && subTotalPrice >= el?.limit;
    });

    var coupen = abc[0];
    setAppliedCoupon(coupen);
    const xyz = coupen?.usedBy?.filter(function (User) {
      return User === user?.id;
    });

    if (xyz?.length > 0) {
      swal("Coupon Already Used", "Try another code");
    } else {
      if (abc.length < 1) {
        swal("Coupon not valid", "Try another code");
        setDiscount(0);
        setCoupon(false);
        setCodeId();
        setCode("");
      } else {
        setOpen(false);
        setDiscount(abc[0]?.discount);
        setCoupon(true);
        setCodeId(abc[0]?._id);
        swal("Hurray Coupon Applied", `You got ${abc[0]?.discount}% discount`);

        finalPrice =
          Number(subTotalPrice) -
          (Number(abc[0]?.discount) / 100) * Number(subTotalPrice);

        setT1(finalPrice);
      }
    }
  };

  const RemoveCoupon = () => {
    setCoupon(false);
    setCode("");
    setDiscount(0);
    setReset(!reset);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleChange = (event) => {
    setCode(event.target.value);
  };

  return (
    <Container>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{ borderBottom: "1px solid #dfdfdf", marginBottom: "10px" }}
        >
          <p>Apply Coupons</p>{" "}
          <button
            style={{ position: "absolute", right: 0, top: 0 }}
            onClick={closeModal}
          >
            close
          </button>
        </div>
        <div>{list?.length < 1 && <p>No coupons found</p>}</div>
        <div>
          {list?.map((item, id) => {
            return (
              <div
                key={id}
                style={{
                  padding: 20,
                  border: "1px solid #dfdfdf",
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  // justifyContent: "space-between",
                }}
              >
                <div
                  style={{ width: "40%", marginLeft: "10px" }}
                  onClick={() => {
                    setCode(item.name);
                    handleCoupon(item?.name);
                    closeModal();
                  }}
                >
                  <div
                    style={{
                      padding: "4px 10px",
                      border: "2px dotted black",
                      height: "30px",
                      width: "max-content",
                    }}
                  >
                    <p>{item.name}</p>
                  </div>
                </div>

                <div
                  style={{
                    textAlign: "justify",
                    marginLeft: "100px",
                    width: "60%",
                  }}
                >
                  <p> Flat discount of {item?.discount}%</p>
                  <p> On purchase of ₹{item?.limit} or more</p>
                </div>
              </div>
            );
          })}
        </div>
      </Modal> */}

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <div
          style={{ borderBottom: "1px solid #dfdfdf", marginBottom: "10px" }}
        >
          <p>Apply Coupons</p>{" "}
        </div>
        <div>{list?.length < 1 && <p>No coupons found</p>}</div>
        <div>
          {list?.map((item, id) => {
            return (
              <div key={id} className="couponContainer">
                <div
                  className="leftContainer"
                  onClick={() => {
                    setCode(item.name);
                    handleCoupon(item?.name);
                    closeModal();
                  }}
                >
                  <div className="couponCodeContainer">
                    <p>{item.name}</p>
                  </div>
                </div>

                <div className="rightContainer">
                  <p style={{ fontSize: "16px" }}>
                    {" "}
                    Flat discount of {item?.discount}%
                  </p>
                  <p style={{ color: "#979797", fontSize: "14px" }}>
                    {" "}
                    On purchase of ₹{item?.limit} or more
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>

      <Wrapper>
        <Title>YOUR BAG</Title>
        <Top>
          {/* <TopButton onClick={() => navigate("/")}>CONTINUE SHOPPING</TopButton> */}
          <TopTexts>
            <TopText>Shopping Bag({products?.length})</TopText>
          </TopTexts>
        </Top>
        <Bottom>
          <Info>
            {products?.map((item, id) => {
              const discount =
                (item.product.discount / 100) * item.product.sell_price;
              const newprice = Number(
                item.product.sell_price - discount
              ).toFixed(2);
              return (
                <div key={id}>
                  <Product>
                    <ProductDetail>
                      <Image src={item?.productImages[0]?.img} />
                      <Details>
                        <ProductName>
                          <b>Product:</b> {item.product.name}
                        </ProductName>
                        <ProductId>
                          <b>ID:</b> #{item.product._id}
                        </ProductId>
                        {item?.color && (
                          <ProductSize>
                            <b>Color:</b> {item.color}
                            <ProductColor color={item.color} />
                          </ProductSize>
                        )}

                        {item?.size && (
                          <ProductSize>
                            <b>Size:</b> {item.size}
                          </ProductSize>
                        )}

                        {item?.flavour && (
                          <ProductSize>
                            <b>Flavour:</b> {item.flavour}
                          </ProductSize>
                        )}
                      </Details>
                    </ProductDetail>
                    <PriceDetail>
                      <ProductAmountContainer>
                        <Remove
                          onClick={() =>
                            handleQuantity("dec", item.product._id)
                          }
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            background:
                              "linear-gradient(to bottom, #ffffff 0%, #eaeaea 99%)",
                          }}
                        />
                        <ProductAmount>{item.quantity}</ProductAmount>
                        {item.quantity < item.product.countInStock && (
                          <Add
                            onClick={() =>
                              handleQuantity("inc", item.product._id)
                            }
                            style={{
                              cursor: "pointer",
                              height: "100%",
                              background:
                                "linear-gradient(to bottom, #ffffff 0%, #eaeaea 99%)",
                            }}
                          />
                        )}
                      </ProductAmountContainer>
                      <DeleteBtn
                        onClick={() => dispatch(remove(item.product._id))}
                      >
                        Remove
                      </DeleteBtn>
                      <ProductPrice>₹ {newprice}</ProductPrice>
                      <CutProductPrice>
                        ₹ {item.product.sell_price}
                      </CutProductPrice>
                    </PriceDetail>
                  </Product>
                  <Hr />
                </div>
              );
            })}
            {products?.length <= 0 && (
              <Title2>There are no items in your basket.</Title2>
            )}
          </Info>
          <Row>
            <Summary>
              {coupon ? (
                <form
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h6>
                      <img
                        src="https://www.bbassets.com/static/v2703/desktop-web/checkout/icon-offer-fill.svg"
                        alt=""
                        className="couponIcon"
                      />
                      {code}
                    </h6>
                    <p>Coupon applied on bill</p>
                  </div>
                  <button
                    qa="applyBtnCKO"
                    type="submit"
                    class="cta"
                    fdprocessedid="7qqwlp"
                    style={{ width: "20%", padding: 3, borderRadius: 5 }}
                    onClick={RemoveCoupon}
                  >
                    Clear
                  </button>
                </form>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      value={code}
                      onChange={handleChange}
                      placeholder="Enter voucher code"
                      ng-disabled="is_voucher_applied"
                      required=""
                      type="text"
                      style={{ width: "90%", padding: 3, borderRadius: 5 }}
                    />
                    <button
                      onClick={() => {
                        handleCoupon(code);
                      }}
                      qa="applyBtnCKO"
                      type="submit"
                      class="cta"
                      fdprocessedid="7qqwlp"
                      style={{ width: "20%", padding: 3, borderRadius: 5 }}
                    >
                      Apply
                    </button>
                  </div>
                  <div>
                    <p onClick={onOpenModal}>Check all coupons</p>
                  </div>
                </>
              )}
            </Summary>
            <Summary>
              <SummaryTitle>ORDER SUMMARY</SummaryTitle>
              <SummaryItem>
                <SummaryItemText>Subtotal</SummaryItemText>
                <SummaryItemPrice>
                  ₹{" "}
                  {products
                    ?.reduce(
                      (acc, item) =>
                        acc +
                        item.quantity *
                          (item.product.sell_price -
                            (item.product.discount / 100) *
                              item.product.sell_price),
                      0
                    )
                    .toFixed(2)}
                </SummaryItemPrice>
              </SummaryItem>
              <SummaryItem>
                <SummaryItemText>Delivery Charge</SummaryItemText>
                <SummaryItemPrice>₹{Number(charge)}</SummaryItemPrice>
              </SummaryItem>
              {discount > 0 && (
                <SummaryItem>
                  <SummaryItemText>Discount</SummaryItemText>
                  <SummaryItemPrice>{discount}%</SummaryItemPrice>
                </SummaryItem>
              )}

              <SummaryItem type="total">
                <SummaryItemText>Total</SummaryItemText>
                <SummaryItemPrice>₹ {finalPrice}</SummaryItemPrice>
              </SummaryItem>
              {products?.length > 0 && (
                <Button
                  onClick={() =>
                    navigate("/shipping", {
                      state: {
                        products,
                        totalPrice: finalPrice,
                        couponCode: codeId,
                        deliveryCharge: charge,
                      },
                    })
                  }
                >
                  Proceed
                </Button>
              )}
            </Summary>
          </Row>
        </Bottom>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default Cart;

const Container = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 130px;
  ${mobile({ marginTop: "120px" })}
`;
const Row = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`
  padding: 30px;
  margin-bottom: 100px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;
const Title2 = styled.h3`
  font-weight: 300;
  text-align: center;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const TopButton = styled.button`
  padding: 10px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(12, 131, 31);
  color: white;
  border: none;
  ${mobile({
    margin: "0 auto",
    borderRadius: "10px",
    fontWeight: "400",
    padding: "4",
    marginBottom: "50px",
  })}
  &:hover {
    opacity: 0.8;
  }
`;

const TopTexts = styled.div`
  ${mobile({ display: "none" })}
`;
const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 10px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}
`;

const Info = styled.div`
  flex: 3;
`;

const Product = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;

const Image = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ProductName = styled.span``;

const ProductId = styled.span``;

const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
`;

const ProductAmount = styled.div`
  font-size: 18px;
  margin: 0 5px;
  padding: 0 5px;
  ${mobile({ margin: "5px 15px" })}
`;

const ProductPrice = styled.div`
  font-size: 24px;
  font-weight: 500;
  ${mobile({ marginBottom: "20px" })}
`;
const CutProductPrice = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: gray;
  text-decoration: line-through;
  ${mobile({ marginBottom: "20px" })}
`;
const DeleteBtn = styled.div`
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 10px;

  ${mobile({ marginBottom: "20px" })}

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: auto;
`;

const SummaryTitle = styled.h4`
  font-weight: 500;
  color: gray;
`;

const SummaryItem = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: rgb(12, 131, 31);
  color: white;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  &:hover {
    opacity: 0.8;
  }
`;
