import { Link } from "react-router-dom";
import "./SearchResult.css";

export const SearchResult = ({ result, imgsrc }) => {
  return (
    <Link
      className="search-result"
      to={`/product/${result?.groupId}`}
      state={{ Data: result }}
    >
      <img src={imgsrc} alt="" />
      {result.name}
    </Link>
  );
};
