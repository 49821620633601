import React from "react";
import Footer from "./../../components/Footer";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div style={{ marginTop: "190px" }}>
      <h1 style={{ textAlign: "center", padding: "20px" }}>About Us</h1>
      <div className="aboutus-content">
        <p
          style={{
            maxWidth: "1024px",
            margin: "0 auto",
          }}
        >
          Welcome to Subhash Superstore, your one-stop online supermarket for
          all your daily needs! Our mission is to make shopping for groceries
          and household essentials as convenient and hassle-free as possible.
          Founded in 2022 by Mr. Sachin Aggarwal, our online store offers a wide
          range of high-quality products at competitive prices. We understand
          how busy and fast-paced life can be, which is why we offer the
          convenience of shopping from the comfort of your own home or office.
          At Subhash Superstore, we believe in providing our customers with the
          best possible shopping experience. That's why we offer fast and
          reliable delivery, secure payment options, and excellent customer
          service. Whether you need fresh fruits and vegetables, dairy products,
          pantry staples, cleaning supplies, or personal care items, we have got
          you covered. We source our products from trusted suppliers and ensure
          that they meet our strict quality standards. Thank you for choosing
          Subhash Superstore as your preferred online supermarket. We look
          forward to serving you and making your shopping experience a
          delightful one!
        </p>
        {/* <img src={dog} /> */}
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
